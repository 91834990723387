<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <h1 class="text-light mb-3">{{ t('bottomBar.bookings') }}</h1>
        <ul
            class="nav nav-tabs"
            id="myTab"
            role="tablist"
            style="position: relative; z-index: 10; font-weight: 500"
        >
            <li
                class="nav-item"
                @click="setCurrentTab('active')"
                role="presentation"
            >
                <button
                    :class="[
                        currentTab == 'active'
                            ? 'shadow-sm active bg-white'
                            : 'text-light',
                    ]"
                    class="nav-link"
                    type="button"
                    role="tab"
                >
                    {{ t('pages.bookings.activeBookings') }}
                </button>
            </li>
            <li
                class="nav-item"
                @click="setCurrentTab('expired')"
                role="presentation"
            >
                <button
                    :class="[
                        currentTab == 'expired'
                            ? 'shadow-sm active bg-white'
                            : 'text-light',
                    ]"
                    class="nav-link"
                    type="button"
                    role="tab"
                >
                    {{ t('pages.bookings.previousBookings') }}
                </button>
            </li>
        </ul>
        <div
            class="shadow-sm bg-white rounded-bottom"
            style="position: relative; z-index: 11"
        >
            <div
                v-if="loadingBookings || !bookingSettings"
                class="d-flex flex-row justify-content-center align-items-center"
                style="min-height: 140px"
            >
                <img
                    src="@/assets/loading-icon.gif"
                    alt="Loading"
                    style="width: 1.5rem"
                />
            </div>
            <div v-else-if="currentTab == 'active'">
                <div
                    v-if="!activeBookings || !activeBookings.length"
                    class="d-flex flex-row justify-content-center align-items-center text-muted"
                    style="min-height: 140px"
                >
                    <div class="text-center">
                        {{ t('pages.bookings.noActive') }}<br />
                        <a
                            href="#"
                            @click="setCurrentTab('expired')"
                            style="font-weight: 500"
                            >{{ t('pages.bookings.seePrevious') }}
                            <i
                                class="fa fa-chevron-right ms-1"
                                style="font-size: 0.8rem"
                            ></i
                        ></a>
                    </div>
                </div>
                <div
                    class="tap-react"
                    :class="[index != 0 ? 'border-top' : '']"
                    v-for="(booking, index) in activeBookings"
                    :key="booking.booking.id"
                    v-else
                >
                    <router-link
                        style="color: inherit !important"
                        :to="'/bookings/' + booking.booking.id"
                    >
                        <h6 class="mx-3 pt-3 mb-2" style="font-weight: 500">
                            <span v-if="yachts">
                                <span v-if="getYacht(booking.booking).brand">
                                    {{ getYacht(booking.booking).brand.name }}
                                </span>
                                {{ getYacht(booking.booking).identity.name }}
                            </span>
                            —
                            <span v-if="booking.bookingType">
                                <span v-if="$i18n.locale == 'fr'">
                                    {{ booking.bookingType.name_fr }}
                                </span>
                                <span v-else>
                                    {{ booking.bookingType.name_en }}
                                </span>
                            </span>
                            (#{{ booking.booking.id }})
                        </h6>
                        <div
                            class="d-flex align-items-center px-3 pb-3 justify-content-between"
                        >
                            <div>
                                <p class="mb-2">
                                    <span
                                        v-if="booking"
                                        class="badge bg-secondary text-light"
                                        :class="diff(booking.booking).classes"
                                    >
                                        <span
                                            v-if="
                                                diff(booking.booking).status ==
                                                'cancelled'
                                            "
                                        >
                                            {{ t('pages.bookings.cancelled') }}
                                        </span>
                                        <span v-else>
                                            {{ diff(booking.booking).status }}
                                            {{ diff(booking.booking).text }}
                                        </span>
                                    </span>
                                </p>
                                <span
                                    class="text-muted"
                                    style="font-size: 0.9rem"
                                >
                                    {{ t('pages.bookings.tapToViewMore') }}
                                </span>
                            </div>
                            <div
                                style="width: 50%"
                                class="d-flex flex-row justify-content-end"
                            >
                                <!-- Icons -->
                                <div
                                    class="d-flex flex-column text-muted align-items-center"
                                    style="gap: 1.3rem; z-index: 100"
                                >
                                    <i
                                        class="dot-icon fa fa-circle py-1"
                                        style="background: white"
                                    ></i>
                                    <i
                                        class="dot-icon fa fa-circle py-1 pb-2"
                                        style="background: white"
                                    ></i>
                                </div>

                                <!-- Content -->
                                <div
                                    class="d-flex flex-column align-items-start"
                                    style="
                                        gap: 0.5rem;
                                        font-size: 0.9rem;
                                        padding-left: 1rem;
                                        margin-left: -0.35rem;
                                        border-left: 2px solid #cbcbcb;
                                    "
                                >
                                    <div>
                                        {{
                                            moment
                                                .utc(
                                                    booking.booking
                                                        .start_timestamp
                                                )
                                                .format('MMM Do HH:mm')
                                        }}
                                    </div>
                                    <div>
                                        {{
                                            moment
                                                .utc(
                                                    booking.booking
                                                        .end_timestamp
                                                )
                                                .format('MMM Do HH:mm')
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div v-else-if="currentTab == 'expired'">
                <div
                    v-if="!expiredBookings || !expiredBookings.length"
                    class="d-flex flex-row justify-content-center align-items-center text-muted"
                    style="min-height: 140px"
                >
                    <div class="text-center">
                        {{ t('pages.bookings.noPrevious') }}<br />
                        <a
                            href="#"
                            @click="setCurrentTab('active')"
                            style="font-weight: 500"
                        >
                            <i
                                class="me-1 fa fa-chevron-left"
                                style="font-size: 0.8rem"
                            ></i
                            >{{ t('pages.bookings.seeActive') }}
                        </a>
                    </div>
                </div>
                <router-link
                    v-else
                    style="color: inherit !important"
                    :to="'/bookings/' + booking.booking.id"
                    v-for="(booking, index) in expiredBookings"
                    class="tap-react d-block"
                    :class="[index != 0 ? 'border-top' : '']"
                    :key="booking.booking.id"
                >
                    <h6 class="mx-3 pt-3 mb-2" style="font-weight: 500">
                        <span v-if="yachts">
                            <span v-if="getYacht(booking.booking).brand">
                                {{ getYacht(booking.booking).brand.name }}
                            </span>
                            {{ getYacht(booking.booking).identity.name }}
                        </span>
                        —
                        <span v-if="booking.bookingType">
                            <span v-if="$i18n.locale == 'fr'">
                                {{ booking.bookingType.name_fr }}
                            </span>
                            <span v-else>
                                {{ booking.bookingType.name_en }}
                            </span>
                        </span>
                        (#{{ booking.booking.id }})
                    </h6>
                    <div
                        class="d-flex align-items-center px-3 pb-3 justify-content-between"
                    >
                        <div>
                            <p class="mb-2">
                                <span
                                    v-if="booking"
                                    class="badge bg-secondary text-light"
                                    :class="diff(booking.booking).classes"
                                >
                                    <span
                                        v-if="
                                            diff(booking.booking).status ==
                                            'cancelled'
                                        "
                                    >
                                        {{ t('pages.bookings.cancelled') }}
                                    </span>
                                    <span v-else>
                                        {{ diff(booking.booking).status }}
                                        {{ diff(booking.booking).text }}
                                    </span>
                                </span>
                            </p>
                            <span class="text-muted" style="font-size: 0.9rem">
                                {{ t('pages.bookings.tapToViewMore') }}
                            </span>
                        </div>
                        <div
                            style="width: 50%"
                            class="d-flex flex-row justify-content-end"
                        >
                            <!-- Icons -->
                            <div
                                class="d-flex flex-column text-muted align-items-center"
                                style="gap: 1.3rem; z-index: 100"
                            >
                                <i
                                    class="dot-icon fa fa-circle py-1"
                                    style="background: white"
                                ></i>
                                <i
                                    class="dot-icon fa fa-circle py-1 pb-2"
                                    style="background: white"
                                ></i>
                            </div>

                            <!-- Content -->
                            <div
                                class="d-flex flex-column align-items-start"
                                style="
                                    gap: 0.5rem;
                                    font-size: 0.9rem;
                                    padding-left: 1rem;
                                    margin-left: -0.35rem;
                                    border-left: 2px solid #cbcbcb;
                                "
                            >
                                <div>
                                    {{
                                        moment
                                            .utc(
                                                booking.booking.start_timestamp
                                            )
                                            .format('MMM Do HH:mm')
                                    }}
                                </div>
                                <div>
                                    {{
                                        moment
                                            .utc(booking.booking.end_timestamp)
                                            .format('MMM Do HH:mm')
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { inject, ref, onMounted } from 'vue';

import { useBookingSettingsStore } from '@/stores/booking-settings-store';
import { useYachtsStore } from '@/stores/yachts-store';
import { useBookingsStore } from '@/stores/bookings-store';

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const { t } = useI18n();
const moment = inject('moment');

const bookingSettingsStore = useBookingSettingsStore();
const { bookingSettings } = storeToRefs(bookingSettingsStore);

const bookingsStore = useBookingsStore();
const { loadingBookings, activeBookings, expiredBookings } =
    storeToRefs(bookingsStore);

const yachtsStore = useYachtsStore();
const { yachts } = storeToRefs(yachtsStore);

const currentTab = ref('active');

function getYacht(booking) {
    const yacht = yachts.value.find((yacht) => {
        return yacht.itself.id == booking.boat_id;
    });
    return yacht;
}

function setCurrentTab(tab) {
    currentTab.value = tab;
}

function diff(booking) {
    const rawCheckIn = moment.utc(booking.start_timestamp);
    const rawCheckOut = moment.utc(booking.end_timestamp);
    const checkIn = moment(
        rawCheckIn.format('YYYY-MM-DD') + ' ' + rawCheckIn.format('HH:mm')
    );
    const checkOut = moment(
        rawCheckOut.format('YYYY-MM-DD') + ' ' + rawCheckOut.format('HH:mm')
    );
    const now = moment();

    const startDiff = checkIn.diff(now, 'hours', true);
    const endDiff = checkOut.diff(now, 'hours', true);
    const startsInText = checkIn.fromNow();
    const endsInText = checkOut.fromNow();

    if (booking.cancelled) {
        return {
            text: t('pages.bookings.cancelled'),
            status: 'cancelled',
            classes: 'bg-danger text-light',
        };
    }

    if (endDiff <= 0) {
        // booking ended
        return {
            text: endsInText,
            status: t('pages.bookings.ended'),
            classes: 'bg-warning text-dark',
        };
    } else if (startDiff <= 0 && endDiff >= 0) {
        // booking active
        return {
            text: endsInText,
            status: t('pages.bookings.active'),
            classes: 'bg-success text-light',
        };
    }
    if (endDiff > 0 && startDiff > 0) {
        // booking hasn't started yet
        return {
            text: startsInText,
            status: t('pages.bookings.starts'),
            classes: 'bg-secondary text-light',
        };
    }
}

onMounted(() => {
    bookingsStore.fetchBookings();
    bookingSettingsStore.fetch();
});
</script>

<style scoped>
.line::after {
    content: '';
    background-color: black;
    width: 1px;
    height: 50px;
    position: absolute;
    left: 0;
}

.line-container {
    position: relative;
}

.arrow-icon {
    font-size: 0.8rem;
}

.dot-icon {
    font-size: 0.5rem;
}
</style>
